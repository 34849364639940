import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { FixedSizeList } from "react-window";
import { debounce } from "lodash";

import {
  DownOutlined,
  MinusCircleOutlined,
  MinusSquareFilled,
  PieChartOutlined,
  PlusSquareFilled,
  UnorderedListOutlined,
} from "@ant-design/icons";

import {
  Row,
  Col,
  Input,
  Cascader,
  notification,
  Pagination,
  Menu,
  Button,
  Dropdown,
  Drawer,
  Checkbox,
  Badge,
  Spin,
  Radio,
  Alert,
  message,
  Empty,
  Tooltip,
  Typography,
} from "antd";

import {
  getProprietorsSearch,
  getProprietorProfileDetail,
} from "../../../redux/actions/searchActions/proprietorsSearchAction";
import { postReport } from "../../../redux/actions/searchActions/commonActions";
import { TPAction, logOut } from "../../../redux/actions/commonActions/actions";
import { PS_SELECT_VIEW } from "../../../redux/actions/searchActions/types";

import {
  LOADING,
  SUCCESS,
  ERROR,
  PROGRESSING,
  PDF,
  EXCEL,
} from "../../../constants/commonConstants/generalConstants";

import {
  initialProprietorReportData,
  PS_customReportData,
  PS_defaultSecondarySelectedFilters_BEK,
  PS_primarySelectedFilters,
  PS_secondarySelectedFilters,
  PS_secondaryFiltersSearchTerms,
  PS_secondarySelectedSorting,
  PS_proprietorsSearchState,
} from "../../../constants/searchConstants/proprietorsSearchConstants";

//--------IP-SUITE GENERAL FUNCTIONS--------
import {
  changeStateValue_L0,
  changeStateValue_L1,
  changeStateValue_L2,
  compareArrays,
} from "../../../functions/commonFunctions/generalFunctions";
import {
  checkNA,
  checkValue,
} from "../../../functions/commonFunctions/checkValueFunctions";

import GeneralMarkCard from "../../commonComponents/generalCards/GeneralMarkCard";
import DateRangePicker from "../../../functions/commonFunctions/dateRangePicker";
import ResultsAnalysis from "../../commonComponents/subComponents/ResultsAnalysis";
import ProprietorSearchFilterSearchBasic from "../../commonComponents/FilterComponents/ProprietorSearchFilterSearchBasic";

const { Text, Title } = Typography;
const { Search } = Input;

export function resetProprietorsSearchState() {
  this.setState(PS_proprietorsSearchState);
}

class ProprietorsSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...PS_proprietorsSearchState,
      range1: null,
      range2: null,
    };

    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
    this.changeStateValue_L1_Debounced = debounce(
      this.changeStateValue_L1,
      400,
    );
    this.changeStateValue_L2 = changeStateValue_L2.bind(this);
    this.setDate1 = this.setDate1.bind(this);
    this.setDate2 = this.setDate2.bind(this);
    resetProprietorsSearchState = resetProprietorsSearchState.bind(this);
    this.handleAllDeselect = this.handleAllDeselect.bind(this);
  }

  async componentDidMount() {
    if (localStorage.getItem("token")) {
      this.props.history.push(this.props.searchPath);
    } else {
      this.props.logOut();
    }
  }

  handlePageChange(pageNumber) {
    switch (this.props.proprietorsSearchStatus) {
      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion",
        });
        break;

      case SUCCESS:
      case ERROR:
        this.props.getProprietorsSearch({
          ...this.props.searchParams,
          page: pageNumber,
        });
        break;

      case PROGRESSING:
        notification["warning"]({
          message: "Report Under Process",
          description:
            "The report has not yet been generated. Please, come back after some time. Till then you can have some cookie 'n' cream.",
        });
        break;

      default:
        notification["error"]({
          message: "Unexpected Error! Contact Mikelegal",
          description:
            "We are sorry that something unexpected has happened. Please, contact MikeLegal",
        });
        break;
    }
  }

  handleApplyPrimaryFilters() {
    switch (this.props.proprietorsSearchStatus) {
      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion.",
        });
        break;

      case SUCCESS:
      case ERROR:
        if (
          this.state?.primarySelectedFilters?.containsTerm.length > 0 ||
          this.state?.primarySelectedFilters?.proprietorType.length > 0
        ) {
          this.props.getProprietorsSearch({
            ...this.props.searchParams,
            page: 1,
            filter_flag: true,
            filter_contains_term:
              this.state?.primarySelectedFilters?.containsTerm,
            filter_proprietor_type: `${this.state?.primarySelectedFilters?.proprietorType}`,
          });
        } else {
          notification["warning"]({
            message: "No Filter Selected",
            description:
              "No filter has been selected. Hence, there will be no change.",
          });
        }
        break;

      default:
        break;
    }
  }

  handleResetPrimaryFilters() {
    switch (this.props.proprietorsSearchStatus) {
      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion.",
        });
        break;

      case SUCCESS:
      case ERROR:
        this.props.getProprietorsSearch({
          ...this.props.searchParams,
          page: 1,
          filter_flag: false,
          filter_contains_term: null,
          filter_proprietor_type: null,
        });
        this.setState({ primarySelectedFilters: PS_primarySelectedFilters });
        break;

      default:
        break;
    }
  }

  handlePageSelection(checked) {
    let { customReportData } = this.state;
    const { count, proprietors } = this.props;
    if (count > 0) {
      if (checked) {
        proprietors.map(
          (eachProprietor) =>
            (customReportData = {
              ...customReportData,
              select_all: false,
              markCount: customReportData?.data[eachProprietor.id]?.select_all
                ? customReportData.markCount
                : customReportData?.data[eachProprietor.id]?.applications
                      .length !== null
                  ? customReportData.markCount +
                    eachProprietor.marks_count -
                    (customReportData?.data[eachProprietor.id]?.applications
                      ?.length || 0)
                  : customReportData.markCount + eachProprietor.marks_count,
              selectAllCount: customReportData.selectAllCount + 1,
              data: {
                ...customReportData.data,
                [eachProprietor.id]: {
                  ...initialProprietorReportData,
                  select_all: checked,
                  filteredMarkCount: eachProprietor.marks_count,
                },
              },
            }),
        );
      } else if (customReportData.markCount > 0) {
        proprietors.map(
          (eachProprietor) =>
            (customReportData = {
              ...customReportData,
              select_all: false,
              markCount: customReportData?.data[eachProprietor.id]?.select_all
                ? customReportData.markCount - eachProprietor.marks_count
                : customReportData.markCount,
              selectAllCount: customReportData.selectAllCount - 1,
              data: {
                ...customReportData.data,
                [eachProprietor.id]: {
                  ...initialProprietorReportData,
                  select_all: checked,
                  filteredMarkCount: eachProprietor.marks_count,
                },
              },
            }),
        );
      }
      this.setState({ customReportData });
    }
  }

  handlePrimarySelectMark(eachProprietor, checked) {
    if (!this.state.customReportData.data[eachProprietor.id]) {
      this.setState({
        customReportData: {
          ...this.state.customReportData,
          markCount:
            this.state.customReportData.markCount + eachProprietor.marks_count,
          selectAllCount: this.state.customReportData.selectAllCount + 1,
          data: {
            ...this.state.customReportData.data,
            [eachProprietor.id]: {
              ...this.state.customReportData.data[eachProprietor.id],
              ...initialProprietorReportData,
              select_all: checked,
              filteredMarkCount: eachProprietor.marks_count,
            },
          },
        },
      });
    } else {
      this.setState({
        customReportData: {
          ...this.state.customReportData,
          markCount: checked
            ? this.state.customReportData.markCount -
              this.state.customReportData.data[eachProprietor.id].markCount +
              this.state.customReportData.data[eachProprietor.id]
                .filteredMarkCount
            : this.state.customReportData.markCount +
              this.state.customReportData.data[eachProprietor.id].markCount -
              this.state.customReportData.data[eachProprietor.id]
                .filteredMarkCount,
          selectAllCount: checked
            ? this.state.customReportData.selectAllCount + 1
            : this.state.customReportData.selectAllCount - 1,
          data: {
            ...this.state.customReportData.data,
            [eachProprietor.id]: {
              ...this.state.customReportData.data[eachProprietor.id],
              select_all: checked,
              filteredMarkCount:
                this.state.customReportData.data[eachProprietor.id]
                  .filteredMarkCount,
            },
          },
        },
      });
    }
  }

  async handleExpandRecord(expandRecord) {
    const { customReportData } = this.state;
    if (this.props.proprietorMarkStatus === LOADING) {
      notification["warning"]({
        message: "Request Under Process",
        description:
          "A request is under process. Kindly, wait for its completion.",
      });
    } else {
      if (expandRecord === false) {
        this.setState({ expandRecord: false });
      } else {
        if (expandRecord.marks_count > 0) {
          if (!this.state.customReportData.data[expandRecord.id]) {
            this.setState({
              secondarySelectedFilters: PS_secondarySelectedFilters,
              secondarySelectedSorting: PS_secondarySelectedSorting,
            });
            this.changeStateValue_L2(
              "customReportData",
              "data",
              `${expandRecord.id}`,
              {
                ...initialProprietorReportData,
                filteredMarkCount: expandRecord.marks_count,
              },
            );
            this.props.getProprietorProfileDetail({
              id: expandRecord.id,
              operation: "MARKS",
              filterFlag: false,
              filters: PS_secondarySelectedFilters,
              sortFlag: false,
              sorting: PS_secondarySelectedSorting,
              pageNumber: 1,
            });
            this.props.getProprietorProfileDetail({
              id: expandRecord.id,
              operation: "MARKS_ANALYSIS",
              filterFlag: false,
              filters: PS_secondarySelectedFilters,
              sortFlag: false,
              sorting: PS_secondarySelectedSorting,
              pageNumber: 1,
            });
          } else {
            let response = await this.props.getProprietorProfileDetail({
              id: expandRecord.id,
              operation: "MARKS",
              filterFlag: customReportData.data[expandRecord.id].filter_flag,
              filters: {
                ...customReportData,
                filterClasses:
                  customReportData.data[expandRecord.id].filters.filter_class,
                filterState:
                  customReportData.data[expandRecord.id].filters.filter_state,
                filterType:
                  customReportData.data[expandRecord.id].filters
                    .filter_trademark_type,
                filterStatus:
                  customReportData.data[expandRecord.id].filters
                    .filter_trademark_status,
                filterProprietor:
                  customReportData.data[expandRecord.id].filters
                    .filter_proprietor,
                date_of_application:
                  customReportData.data[expandRecord.id].filters
                    .filter_application_date,
                date_of_usage:
                  customReportData.data[expandRecord.id].filters
                    .filter_date_of_usage,
                date_of_usage_proposed:
                  customReportData.data[expandRecord.id].filters
                    .filter_date_of_usage_proposed,
                containsTerm:
                  customReportData.data[expandRecord.id].filters
                    .filter_contains_term,
              },
              sortFlag: customReportData.data[expandRecord.id].sort_flag,
              sorting: {
                ...customReportData,
                sortType: customReportData.data[expandRecord.id].sort.sort_type,
                sortOn: customReportData.data[expandRecord.id].sort.sort_on,
              },
              pageNumber: 1,
            });
            if (response?.status === SUCCESS && expandRecord?.id) {
              this.setState({
                secondarySelectedFilters: {
                  ...this.state.secondarySelectedFilters,
                  filterFlag:
                    customReportData.data[expandRecord.id].filter_flag,
                  filterClasses:
                    customReportData.data[expandRecord.id].filters.filter_class,
                  filterState:
                    customReportData.data[expandRecord.id].filters.filter_state,
                  filterType:
                    customReportData.data[expandRecord.id].filters
                      .filter_trademark_type,
                  filterStatus:
                    customReportData.data[expandRecord.id].filters
                      .filter_trademark_status,
                  filterProprietor:
                    customReportData.data[expandRecord.id].filters
                      .filter_proprietor,
                  date_of_application:
                    customReportData.data[expandRecord.id].filters
                      .filter_application_date,
                  date_of_usage:
                    customReportData.data[expandRecord.id].filters
                      .filter_date_of_usage,
                  date_of_usage_proposed:
                    customReportData.data[expandRecord.id].filters
                      .filter_date_of_usage_proposed,
                  containsTerm:
                    customReportData.data[expandRecord.id].filters
                      .filter_contains_term,
                },
                secondarySorting: {
                  ...this.state.secondarySorting,
                  sortFlag: customReportData.data[expandRecord.id].sort_flag,
                  sortType:
                    this.state.customReportData.data[expandRecord.id].sort
                      .sort_type,
                  sortOn:
                    this.state.customReportData.data[expandRecord.id].sort
                      .sort_on,
                },
              });
            }
          }
          this.setState({ expandRecord: expandRecord.id });
        } else {
          notification["warning"]({
            message: "0 Trademark",
            description: "There are no trademark against this proprietor.",
          });
        }
      }
    }
  }

  async handleApplySecondaryFilters() {
    switch (this.props.proprietorMarkStatus) {
      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion.",
        });
        break;

      case SUCCESS:
      case ERROR:
        const {
          filterClasses,
          filterState,
          filterType,
          filterStatus,
          filterProprietor,
          date_of_application,
          date_of_usage,
          date_of_usage_proposed,
          containsTerm,
        } = this.props.proprietorMarkFilters;

        let filterFlag = false;

        const { secondarySelectedFilters, secondarySelectedSorting } =
          this.state;

        if (
          !compareArrays(
            secondarySelectedFilters.filterClasses,
            PS_secondarySelectedFilters.filterClasses,
          ) ||
          !compareArrays(
            secondarySelectedFilters.filterState,
            PS_secondarySelectedFilters.filterState,
          ) ||
          !compareArrays(
            secondarySelectedFilters.filterType,
            PS_secondarySelectedFilters.filterType,
          ) ||
          !compareArrays(
            secondarySelectedFilters.filterStatus,
            PS_secondarySelectedFilters.filterStatus,
          ) ||
          !compareArrays(
            secondarySelectedFilters.filterProprietor,
            PS_secondarySelectedFilters.filterProprietor,
          ) ||
          secondarySelectedFilters.date_of_application !==
            PS_secondarySelectedFilters.date_of_application ||
          secondarySelectedFilters.date_of_usage !==
            PS_secondarySelectedFilters.date_of_usage ||
          secondarySelectedFilters.date_of_usage_proposed !==
            PS_secondarySelectedFilters.date_of_usage_proposed ||
          secondarySelectedFilters.containsTerm !==
            PS_secondarySelectedFilters.containsTerm
        ) {
          filterFlag = true;
        } else {
          filterFlag = false;
        }

        if (
          !compareArrays(
            filterClasses,
            secondarySelectedFilters.filterClasses,
          ) ||
          !compareArrays(filterState, secondarySelectedFilters.filterState) ||
          !compareArrays(filterType, secondarySelectedFilters.filterType) ||
          !compareArrays(filterStatus, secondarySelectedFilters.filterStatus) ||
          !compareArrays(
            filterProprietor,
            secondarySelectedFilters.filterProprietor,
          ) ||
          date_of_application !==
            secondarySelectedFilters.date_of_application ||
          date_of_usage !== secondarySelectedFilters.date_of_usage ||
          date_of_usage_proposed !==
            secondarySelectedFilters.date_of_usage_proposed ||
          containsTerm !== secondarySelectedFilters.containsTerm
        ) {
          let response = await this.props.getProprietorProfileDetail({
            id: this.state.expandRecord,
            operation: "MARKS",
            filterFlag: filterFlag,
            filters: secondarySelectedFilters,
            sortFlag: false,
            sorting: PS_secondarySelectedSorting,
            pageNumber: 1,
          });
          if (response.status === SUCCESS) {
            this.setState({
              selectedFilters: {
                ...this.state.selectedFilters,
                filterFlag: true,
              },
              customReportData: {
                ...this.state.customReportData,
                markCount: this.state.customReportData.data[
                  this.state.expandRecord
                ].select_all
                  ? this.state.customReportData.markCount -
                    this.state.customReportData.data[this.state.expandRecord]
                      .filteredMarkCount +
                    response.filteredMarkCount
                  : this.state.customReportData.markCount,
                data: {
                  ...this.state.customReportData.data,
                  [this.state.expandRecord]: {
                    ...this.state.customReportData.data[
                      this.state.expandRecord
                    ],
                    filteredMarkCount: response.filteredMarkCount,
                    filter_flag: filterFlag,
                    filters: {
                      filter_class: secondarySelectedFilters.filterClasses,
                      filter_state: secondarySelectedFilters.filterState,
                      filter_trademark_status:
                        secondarySelectedFilters.filterStatus,
                      filter_trademark_type:
                        secondarySelectedFilters.filterType,
                      filter_proprietor:
                        secondarySelectedFilters.filterProprietor,
                      filter_application_date:
                        secondarySelectedFilters.date_of_application,
                      filter_date_of_usage:
                        secondarySelectedFilters.date_of_usage,
                      filter_date_of_usage_proposed:
                        secondarySelectedFilters.date_of_usage_proposed,
                      filter_contains_term:
                        secondarySelectedFilters.containsTerm,
                    },
                    sort_flag: secondarySelectedSorting.sortFlag,
                    sort: {
                      sort_type: secondarySelectedSorting.sortType,
                      sort_on: secondarySelectedSorting.sortOn,
                    },
                  },
                },
              },
            });
          }
        } else {
          notification["warning"]({
            message: "Same Filters Applied",
            description:
              "Same filters were applied during the last request. No change in response.",
          });
        }
        break;

      default:
        break;
    }
  }

  setDate1(val) {
    this.setState({ range1: val });
  }

  setDate2(val) {
    this.setState({ range2: val });
  }

  async handleResetSecondaryFilters() {
    this.setState({ range1: null, range2: null });
    switch (this.props.proprietorMarkStatus) {
      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion.",
        });
        break;

      case SUCCESS:
      case ERROR:
        const {
          filterClasses,
          filterState,
          filterType,
          filterStatus,
          filterProprietor,
          date_of_application,
          date_of_usage,
          date_of_usage_proposed,
          containsTerm,
        } = this.props.proprietorMarkFilters;

        const { secondarySelectedSorting } = this.state;

        if (
          !compareArrays(
            filterClasses,
            PS_secondarySelectedFilters.filterClasses,
          ) &&
          !compareArrays(
            filterState,
            PS_secondarySelectedFilters.filterState,
          ) &&
          !compareArrays(filterType, PS_secondarySelectedFilters.filterType) &&
          !compareArrays(
            filterStatus,
            PS_secondarySelectedFilters.filterStatus,
          ) &&
          !compareArrays(
            filterProprietor,
            PS_secondarySelectedFilters.filterProprietor,
          ) &&
          date_of_application !==
            PS_secondarySelectedFilters.date_of_application &&
          date_of_usage !== PS_secondarySelectedFilters.date_of_usage &&
          date_of_usage_proposed !==
            PS_secondarySelectedFilters.date_of_usage_proposed &&
          containsTerm !== PS_secondarySelectedFilters.containsTerm
        ) {
          notification["warning"]({
            message: "Same Filters Applied",
            description:
              "Same filters were applied during the last request. No change in response.",
          });
        } else {
          let response = await this.props.getProprietorProfileDetail({
            id: this.state.expandRecord,
            operation: "MARKS",
            filterFlag: false,
            filters: PS_secondarySelectedFilters,
            sortFlag: false,
            sorting: PS_secondarySelectedSorting,
            pageNumber: 1,
          });
          if (response.status === SUCCESS) {
            this.setState({
              secondarySelectedFilters: PS_secondarySelectedFilters,
              secondaryFiltersSearchTerms: PS_secondaryFiltersSearchTerms,
              customReportData: {
                ...this.state.customReportData,
                markCount: this.state.customReportData.data[
                  this.state.expandRecord
                ].select_all
                  ? this.state.customReportData.markCount -
                    this.state.customReportData.data[this.state.expandRecord]
                      .filteredMarkCount +
                    response.filteredMarkCount
                  : this.state.customReportData.markCount,
                data: {
                  ...this.state.customReportData.data,
                  [this.state.expandRecord]: {
                    ...this.state.customReportData.data[
                      this.state.expandRecord
                    ],
                    filteredMarkCount: response.filteredMarkCount,
                    filters: PS_defaultSecondarySelectedFilters_BEK,
                    filter_flag: false,
                    sort_flag: secondarySelectedSorting.sortFlag,
                    sort: {
                      sort_type: secondarySelectedSorting.sortType,
                      sort_on: secondarySelectedSorting.sortOn,
                    },
                  },
                },
              },
            });
          }
        }
        break;

      default:
        notification["error"]({
          message: "Unable to Reset! Contact Mikelegal",
          description:
            "We are sorry that something unexpected has happened. Please, contact MikeLegal",
        });
        break;
    }
  }

  async handleApplySecondarySorting(sortValue) {
    switch (this.props.proprietorMarkStatus) {
      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion.",
        });
        break;

      case SUCCESS:
      case ERROR:
        const { secondarySelectedSorting } = this.state;
        const selectedFilters = this.props.proprietorMarkFilters;
        const pageNumber = this.props.proprietorMarkPageNumber;

        if (sortValue.length !== secondarySelectedSorting.sortValue) {
          if (sortValue[0] === "CLEAR") {
            let response = await this.props.getProprietorProfileDetail({
              id: this.state.expandRecord,
              operation: "MARKS",
              filterFlag: selectedFilters?.filterFlag,
              filters: selectedFilters,
              sortFlag: false,
              sorting: PS_secondarySelectedSorting,
              pageNumber: pageNumber,
            });
            if (response.status === SUCCESS) {
              this.setState({
                sorting: PS_secondarySelectedSorting,
                customReportData: {
                  ...this.state.customReportData,
                  markCount: this.state.customReportData.data[
                    this.state.expandRecord
                  ].select_all
                    ? this.state.customReportData.markCount -
                      this.state.customReportData.data[this.state.expandRecord]
                        .filteredMarkCount +
                      response.filteredMarkCount
                    : this.state.customReportData.markCount,
                  data: {
                    ...this.state.customReportData.data,
                    [this.state.expandRecord]: {
                      ...this.state.customReportData.data[
                        this.state.expandRecord
                      ],
                      filteredMarkCount: response.filteredMarkCount,
                      sort_flag: false,
                      sort: {
                        sort_type: "",
                        sort_on: "",
                      },
                    },
                  },
                },
              });
            }
          } else {
            let response = await this.props.getProprietorProfileDetail({
              id: this.state.expandRecord,
              operation: "MARKS",
              filterFlag: selectedFilters?.filterFlag,
              filters: selectedFilters,
              sortFlag: true,
              sorting: {
                ...secondarySelectedSorting,
                sortType: sortValue[0],
                sortOn: sortValue[1],
              },
              pageNumber: pageNumber,
            });
            if (response.status === SUCCESS) {
              this.setState({
                secondarySelectedSorting: {
                  ...this.state.secondarySelectedSorting,
                  sortType: sortValue[0],
                  sortOn: sortValue[1],
                  sortFlag: true,
                  sortValue: sortValue,
                },
                customReportData: {
                  ...this.state.customReportData,
                  markCount: this.state.customReportData.data[
                    this.state.expandRecord
                  ].select_all
                    ? this.state.customReportData.markCount -
                      this.state.customReportData.data[this.state.expandRecord]
                        .filteredMarkCount +
                      response.filteredMarkCount
                    : this.state.customReportData.markCount,
                  data: {
                    ...this.state.customReportData.data,
                    [this.state.expandRecord]: {
                      ...this.state.customReportData.data[
                        this.state.expandRecord
                      ],
                      filteredMarkCount: response.filteredMarkCount,
                      sort_flag: true,
                      sort: {
                        sort_type: sortValue[0],
                        sort_on: sortValue[1],
                      },
                    },
                  },
                },
              });
            }
          }
        } else {
          message.error("please select value!");
        }
        break;

      default:
        notification["error"]({
          message: "Unable to Reset! Contact Mikelegal",
          description:
            "We are sorry that something unexpected has happened. Please, contact MikeLegal",
        });
        break;
    }
  }

  async handleSecondaryPageChange(pageNumber) {
    switch (this.props.proprietorMarkStatus) {
      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion.",
        });
        break;

      case SUCCESS:
      case ERROR:
        const sorting = this.props.proprietorMarkSorting;
        const selectedFilters = this.props.proprietorMarkFilters;
        await this.props.getProprietorProfileDetail({
          id: this.state.expandRecord,
          operation: "MARKS",
          filterFlag: selectedFilters?.filterFlag,
          filters: selectedFilters,
          sortFlag: sorting?.sortFlag,
          sorting: sorting,
          pageNumber: pageNumber,
        });
        this.setState({
          pageNumber: pageNumber,
        });
        break;

      default:
        break;
    }
  }

  handleSecondarySelectMark(mardId, application_number, checked) {
    if (!this.state.customReportData.data[this.state.expandRecord]) {
      this.setState({
        customReportData: {
          ...this.state.customReportData,
          ...PS_customReportData,
          data: {
            ...this.state.customReportData.data,
            [this.state.expandRecord]: {
              ...this.state.customReportData.data[this.state.expandRecord],
              ...initialProprietorReportData,
            },
          },
        },
      });
    } else {
      this.setState({
        customReportData: {
          ...this.state.customReportData,
          markCount: checked
            ? this.state.customReportData.markCount + 1
            : this.state.customReportData.markCount - 1,
          data: {
            ...this.state.customReportData.data,
            [this.state.expandRecord]: {
              ...this.state.customReportData.data[this.state.expandRecord],
              applications: checked
                ? [
                    ...this.state.customReportData.data[this.state.expandRecord]
                      .applications,
                    application_number,
                  ]
                : this.state.customReportData.data[
                    this.state.expandRecord
                  ].applications.filter((e) => e !== application_number),
              selected_mark_id_list: checked
                ? [
                    ...this.state.customReportData.data[this.state.expandRecord]
                      .selected_mark_id_list,
                    mardId,
                  ]
                : this.state.customReportData.data[
                    this.state.expandRecord
                  ].selected_mark_id_list.filter((e) => e !== mardId),
              markCount: checked
                ? this.state.customReportData.data[this.state.expandRecord]
                    .markCount + 1
                : this.state.customReportData.data[this.state.expandRecord]
                    .markCount - 1,
            },
          },
        },
      });
    }
  }

  handleCreateReport() {
    switch (this.props.proprietorsSearchStatus) {
      case SUCCESS:
      case ERROR:
        this.changeStateValue_L1("drawersVisibility", "customReport", true);
        break;

      case LOADING:
        notification["warning"]({
          message: "Request Under Process",
          description:
            "A request is under process. Kindly, wait for its completion.",
        });
        break;

      default:
        break;
    }
  }

  async handleConfirmReport() {
    let response = await this.props.postReport({
      report_source: "PROPRIETOR",
      report_type: this.state?.customReportData?.reportType,
      report_file:
        this.state?.customReportData?.reportFormat.length > 0 ? true : false,
      report_file_types: this.state?.customReportData?.reportFormat,
      report_file_name: this.state?.customReportData?.reportName,
      data: this.state?.customReportData?.data,
      select_all: this.state?.customReportData?.select_all,
      filter_flag: true,
      filter_contains_term: this.state?.primarySelectedFilters?.containsTerm,
      filter_proprietor_type:
        this.state?.primarySelectedFilters?.proprietorType,
      search_type: this.props?.searchParams?.search_type,
      query: this.props?.searchParams?.query,
    });
    if (response.status === SUCCESS) {
      this.changeStateValue_L1("drawersVisibility", "customReport", false);
    }
  }

  handleAllDeselect() {
    let { customReportData } = this.state;
    customReportData = {
      ...customReportData,
      markCount: 0,
      data: {},
      select_all: false,
    };
    this.setState({ customReportData });
  }

  render() {
    const filterMenuScroll = {
      maxHeight: "175px",
      minWidth: "150px",
      overflowY: "auto",
    };

    const {
      secondarySelectedFilters,
      secondaryFiltersVisibility,
      secondaryFiltersSearchTerms,
      secondarySelectedSorting,
      customReportData,
    } = this.state;

    const {
      proprietorMark,
      proprietorMarkStatus,
      proprietorMarkPageNumber,
      proprietorAnalysis,
      proprietorAnalysisStatus,
      selectedView,
    } = this.props;

    let noFilterData = (
      <Menu>
        <Menu.Item>No Filters Data Found</Menu.Item>
      </Menu>
    );

    let proprietorTypeMenu = noFilterData;
    let classesMenu = noFilterData;
    let stateMenu = noFilterData;
    let typeMenu = noFilterData;
    let statusMenu = noFilterData;
    let proprietorMenu = noFilterData;

    const moreActionsMenu = (
      <Menu
        selectable={true}
        multiple={true}
        selectedKeys={[]}
        style={filterMenuScroll}
      >
        <Menu.Item
          disabled={this.state?.customReportData?.select_all === true}
          key="Select page"
          onClick={() => this.handlePageSelection(true)}
        >
          Select page
        </Menu.Item>
        <Menu.Item
          disabled={this.state?.customReportData?.select_all === true}
          key="Deselect page"
          onClick={() => this.handlePageSelection(false)}
        >
          Deselect page
        </Menu.Item>

        <Menu.Item
          key="Select All"
          onClick={() =>
            this.changeStateValue_L1("customReportData", "select_all", true)
          }
        >
          Select All
        </Menu.Item>
        <Menu.Item key="DeSelect All" onClick={this.handleAllDeselect}>
          Deselect All
        </Menu.Item>
      </Menu>
    );

    if (this.props.filters && this.props.proprietorsSearchStatus !== LOADING) {
      const { proprietor_type } = this.props.filters;

      if (proprietor_type !== null && proprietor_type.length > 0) {
        proprietorTypeMenu = (
          <Menu
            selectable={true}
            multiple={true}
            selectedKeys={this.state?.primarySelectedFilters?.proprietorType}
            onSelect={(event) =>
              this.changeStateValue_L1(
                "primarySelectedFilters",
                "proprietorType",
                event.selectedKeys,
              )
            }
            onDeselect={(event) =>
              this.changeStateValue_L1(
                "primarySelectedFilters",
                "proprietorType",
                event.selectedKeys,
              )
            }
          >
            {proprietor_type.map((e) => (
              <Menu.Item key={e}>{e}</Menu.Item>
            ))}
          </Menu>
        );
      }
    }

    if (proprietorMarkStatus === SUCCESS) {
      let filters = proprietorMark.filters;
      let classes = [...new Set(filters.class)].sort(function (a, b) {
        return a - b;
      });
      let state = [...new Set(filters.state)].sort(function (a, b) {
        return a - b;
      });
      let type = [...new Set(filters.type)].sort(function (a, b) {
        return a - b;
      });
      let status = [...new Set(filters.status)].sort(function (a, b) {
        return a - b;
      });
      let proprietor = [...new Set(filters.proprietor)].sort(function (a, b) {
        return a - b;
      });

      if (classes !== null && classes.length > 0) {
        classesMenu = (
          <ProprietorSearchFilterSearchBasic
            data={classes}
            searchFilters={secondaryFiltersSearchTerms}
            search_key={"classes"}
            filter_key={"filterClasses"}
            selectedFilters={secondarySelectedFilters}
            onSelect={this.changeStateValue_L1}
            noSearch={"NOSearch"}
          />
        );
      }

      if (state !== null && state.length > 0) {
        stateMenu = (
          <ProprietorSearchFilterSearchBasic
            data={state}
            searchFilters={secondaryFiltersSearchTerms}
            search_key={"state"}
            filter_key={"filterState"}
            selectedFilters={secondarySelectedFilters}
            onSelect={this.changeStateValue_L1}
            noSearch={"NOSearch"}
          />
        );
      }

      if (type !== null && type.length > 0) {
        typeMenu = (
          <ProprietorSearchFilterSearchBasic
            data={type}
            searchFilters={secondaryFiltersSearchTerms}
            search_key={"type"}
            filter_key={"filterType"}
            selectedFilters={secondarySelectedFilters}
            onSelect={this.changeStateValue_L1}
            noSearch={"NOSearch"}
            type={"name"}
          />
        );
      }

      if (status !== null && status.length > 0) {
        statusMenu = (
          <ProprietorSearchFilterSearchBasic
            data={status}
            searchFilters={secondaryFiltersSearchTerms}
            search_key={"status"}
            filter_key={"filterStatus"}
            selectedFilters={secondarySelectedFilters}
            onSelect={this.changeStateValue_L1}
            noSearch={"NOSearch"}
          />
        );
      }

      if (proprietor !== null && proprietor.length > 0) {
        proprietorMenu = (
          <ProprietorSearchFilterSearchBasic
            data={proprietor}
            searchFilters={secondaryFiltersSearchTerms}
            search_key={"proprietor"}
            filter_key={"filterProprietor"}
            selectedFilters={secondarySelectedFilters}
            onSelect={this.changeStateValue_L1}
            type={"name"}
          />
        );
      }
    }

    return (
      <div className="Proprietor-Search">
        <header
          style={{
            textAlign: "center",
            marginBottom: "10px",
            width: "100%",
            alignSelf: "center",
          }}
        >
          <Dropdown
            overlay={proprietorTypeMenu}
            overlayClassName="Filters-Menu"
            open={this.state.primarySelectedFilters?.proprietorTypeMenu}
            onVisibleChange={(event) =>
              this.changeStateValue_L1(
                "primarySelectedFilters",
                "proprietorTypeMenu",
                event,
              )
            }
          >
            <Button
              className={`${
                this.state.primarySelectedFilters?.proprietorType.length > 0
                  ? "Search-Filter-Selected"
                  : "Search-Filter-Empty"
              }`}
            >
              Proprietor Types
              <DownOutlined style={{ fontSize: "12px" }} />
            </Button>
          </Dropdown>
          <Search
            placeholder={`Search ${
              this.props.searchParams.search_type &&
              this.props.searchParams.search_type === "Address"
                ? "name, trading as and type details"
                : "address"
            }`}
            onChange={(event) =>
              this.changeStateValue_L1_Debounced(
                "primarySelectedFilters",
                "containsTerm",
                event.target.value,
              )
            }
            style={{
              minWidth: "150px",
              width: "30%",
              margin: "0px 6px 0px 3px",
            }}
            onSearch={() => this.handleApplyPrimaryFilters()}
            loading={this.props.proprietorsSearchStatus === LOADING}
            enterButton={
              <Button
                style={{
                  backgroundColor: "#4285f4",
                  color: "#ffffff",
                  fontWeight: "600",
                }}
              >
                {this.props.proprietorsSearchStatus === LOADING ? "" : "Apply"}
              </Button>
            }
          />
          <Button
            onClick={() => this.handleResetPrimaryFilters()}
            style={{ padding: "0px 3px" }}
            type="link"
          >
            Reset
          </Button>
          <Badge
            overflowCount={9999}
            count={
              this.state.customReportData.select_all
                ? "All"
                : this.state.customReportData.markCount
            }
            style={{ marginTop: "5px" }}
          >
            <Button
              disabled={
                this.state.customReportData.markCount > 0 ||
                this.state.customReportData.select_all
                  ? false
                  : true
              }
              onClick={() => this.handleCreateReport()}
            >
              Create Report
            </Button>
          </Badge>
          <MinusCircleOutlined
            style={{
              margin: "10px",
              fontSize: "14px",
              visibility: `${
                customReportData.data[this.state.expandRecord]
                  ? customReportData.select_page ||
                    customReportData.markCount > 0 ||
                    customReportData.selectAllCount > 0 ||
                    customReportData.data[this.state.expandRecord].select_all ||
                    customReportData.data[this.state.expandRecord]
                      .select_page ||
                    customReportData.data[this.state.expandRecord].markCount > 0
                    ? ""
                    : "hidden"
                  : customReportData.select_page ||
                      customReportData.markCount > 0 ||
                      customReportData.selectAllCount > 0
                    ? ""
                    : "hidden"
              }`,
            }}
            onClick={() => {
              if (this.state.expandRecord) {
                this.setState({
                  customReportData: {
                    ...PS_customReportData,
                    data: {
                      [this.state.expandRecord]: {
                        ...initialProprietorReportData,
                        filter_flag:
                          this.state.customReportData.data[
                            this.state.expandRecord
                          ].filter_flag,
                        filters:
                          this.state.customReportData.data[
                            this.state.expandRecord
                          ].filters,
                        sort_flag:
                          this.state.customReportData.data[
                            this.state.expandRecord
                          ].sort_flag,
                        sort: this.state.customReportData.data[
                          this.state.expandRecord
                        ].sort,
                        filteredMarkCount:
                          this.state.customReportData.data[
                            this.state.expandRecord
                          ].filteredMarkCount,
                      },
                    },
                  },
                });
              } else {
                this.setState({ customReportData: PS_customReportData });
              }
            }}
          />
          <Dropdown
            disabled={this.props.proprietorsSearchStatus === LOADING}
            overlay={moreActionsMenu}
            overlayClassName="Filters-Menu"
            open={this.state.primaryFiltersVisibility.moreActionsMenu}
            onVisibleChange={(event) =>
              this.changeStateValue_L1(
                "primaryFiltersVisibility",
                "moreActionsMenu",
                event,
              )
            }
          >
            <Button className="Search-More-Actions">
              <UnorderedListOutlined
                style={{
                  fontSize: "16px",
                  position: "relative",
                  bottom: "2px",
                }}
              />
            </Button>
          </Dropdown>
        </header>
        <div
          className="General-Content vertical_scroll"
          style={{
            height: `${
              this.props.searchAlert.length > 0
                ? "calc(100vh - 215px)"
                : "calc(100vh - 180px)"
            }`,
          }}
        >
          <Alert
            style={{ margin: "5px" }}
            type="warning"
            message="Note : Proprietor Marks may not include some of the marks assigned to the proprietor."
          />

          {this.props.proprietorsSearchStatus === SUCCESS &&
            this.props.count > 0 &&
            this.props.proprietors.map((eachProprietor) => (
              <React.Fragment key={`${eachProprietor.id}`}>
                <div id={eachProprietor.id} className="Record-Row">
                  <div className="Record-Row-Cell" style={{ width: "20px" }}>
                    <Checkbox
                      disabled={
                        this.state.customReportData.select_all ||
                        this.state.customReportData.select_all
                      }
                      checked={
                        this.state.customReportData.data[eachProprietor.id]
                          ? this.state.customReportData.data[eachProprietor.id]
                              .select_all ||
                            this.state.customReportData.data[eachProprietor.id]
                              .select_page ||
                            this.state.customReportData.select_all ||
                            this.state.customReportData.select_all
                          : this.state.customReportData.select_all ||
                            this.state.customReportData.select_all
                      }
                      onClick={(event) =>
                        this.handlePrimarySelectMark(
                          eachProprietor,
                          event.target.checked,
                        )
                      }
                    />
                  </div>
                  <Tooltip title={`Name- ${checkNA(eachProprietor.name)}`}>
                    <div
                      className="Record-Row-Cell Application-Number"
                      onClick={() => {
                        sessionStorage.setItem("proprietor", eachProprietor.id);
                        window.open(
                          `/search/proprietor-profile/${eachProprietor.id}`,
                        );
                      }}
                    >
                      {checkNA(eachProprietor.name)}
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={`Trading As- ${checkNA(eachProprietor.trading_as)}`}
                  >
                    <div className="Record-Row-Cell">
                      {checkNA(eachProprietor.trading_as)}
                    </div>
                  </Tooltip>
                  <Tooltip title={`Type- ${checkNA(eachProprietor.type)}`}>
                    <div className="Record-Row-Cell">
                      {checkNA(eachProprietor.type)}
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={`Type Details- ${checkNA(
                      eachProprietor.type_details,
                    )}`}
                  >
                    <div className="Record-Row-Cell">
                      {checkNA(eachProprietor.type_details)}
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={`Address- ${checkNA(eachProprietor.address)}`}
                  >
                    <div className="Record-Row-Cell">
                      {checkNA(eachProprietor.address)}
                    </div>
                  </Tooltip>
                  <div className="Record-Row-Cell">
                    {eachProprietor.marks_count > 0 ? (
                      <a
                        href={`#${eachProprietor.id}`}
                        onClick={() =>
                          this.handleExpandRecord(
                            this.state.expandRecord === eachProprietor.id
                              ? false
                              : eachProprietor,
                          )
                        }
                      >
                        {`${eachProprietor.marks_count} Trademarks`}
                        <DownOutlined />
                      </a>
                    ) : (
                      <Text>{`${eachProprietor.marks_count} Trademarks`}</Text>
                    )}
                  </div>
                  <div className="Record-Row-Cell" style={{ width: "50px" }}>
                    {this.state.customReportData.data[eachProprietor.id] &&
                      (this.state.customReportData.data[eachProprietor.id]
                        .select_all ||
                        this.state.customReportData.data[eachProprietor.id]
                          .markCount > 0) && (
                        <>
                          <Badge
                            overflowCount={999}
                            count={
                              this.state.customReportData.data[
                                eachProprietor.id
                              ].select_all
                                ? this.state.customReportData.data[
                                    eachProprietor.id
                                  ].filteredMarkCount
                                : this.state.customReportData.data[
                                    eachProprietor.id
                                  ].markCount
                            }
                          />
                          {!this.state.customReportData.data[eachProprietor.id]
                            .select_all && (
                            <MinusCircleOutlined
                              style={{ marginLeft: "5px", fontSize: "14px" }}
                              onClick={() =>
                                this.setState({
                                  customReportData: {
                                    ...this.state.customReportData,
                                    markCount:
                                      this.state.customReportData.markCount -
                                      this.state.customReportData.data[
                                        eachProprietor.id
                                      ].markCount,
                                    data: {
                                      ...this.state.customReportData.data,
                                      [eachProprietor.id]: {
                                        ...this.state.customReportData.data[
                                          eachProprietor.id
                                        ],
                                        selected_mark_id_list: [],
                                        applications: [],
                                        markCount: 0,
                                        filteredMarkCount: 0,
                                      },
                                    },
                                  },
                                })
                              }
                            />
                          )}
                        </>
                      )}
                  </div>
                </div>
                {this.state.expandRecord === eachProprietor.id && (
                  <div
                    style={{
                      height: "calc(100% - 70px)",
                      overflow: "auto",
                      margin: "0px 5px",
                      borderRadius: "5px",
                      border: "1px solid #c9c9c9",
                    }}
                  >
                    <header className="General-Header Box-Shadow">
                      <Row>
                        <Col span={24}>
                          <div className="alignC">
                            <Dropdown
                              overlay={classesMenu}
                              overlayClassName="Filters-Menu"
                              open={secondaryFiltersVisibility.classesMenu}
                              onVisibleChange={(event) =>
                                this.changeStateValue_L1(
                                  "secondaryFiltersVisibility",
                                  "classesMenu",
                                  event,
                                )
                              }
                            >
                              <Button
                                className={`${
                                  secondarySelectedFilters.filterClasses
                                    .length > 0
                                    ? "Search-Filter-Selected"
                                    : "Search-Filter-Empty"
                                }`}
                              >
                                Class
                                <DownOutlined />
                              </Button>
                            </Dropdown>
                            <Dropdown
                              overlay={stateMenu}
                              overlayClassName="Filters-Menu"
                              open={secondaryFiltersVisibility.stateMenu}
                              onVisibleChange={(event) =>
                                this.changeStateValue_L1(
                                  "secondaryFiltersVisibility",
                                  "stateMenu",
                                  event,
                                )
                              }
                            >
                              <Button
                                className={`${
                                  secondarySelectedFilters.filterState.length >
                                  0
                                    ? "Search-Filter-Selected"
                                    : "Search-Filter-Empty"
                                }`}
                              >
                                State
                                <DownOutlined />
                              </Button>
                            </Dropdown>
                            <Dropdown
                              overlay={typeMenu}
                              overlayClassName="Filters-Menu"
                              open={secondaryFiltersVisibility.typeMenu}
                              onVisibleChange={(event) =>
                                this.changeStateValue_L1(
                                  "secondaryFiltersVisibility",
                                  "typeMenu",
                                  event,
                                )
                              }
                            >
                              <Button
                                className={`${
                                  secondarySelectedFilters.filterType.length > 0
                                    ? "Search-Filter-Selected"
                                    : "Search-Filter-Empty"
                                }`}
                              >
                                Type
                                <DownOutlined />
                              </Button>
                            </Dropdown>
                            <Dropdown
                              overlay={statusMenu}
                              overlayClassName="Filters-Menu"
                              open={secondaryFiltersVisibility.statusMenu}
                              onVisibleChange={(event) =>
                                this.changeStateValue_L1(
                                  "secondaryFiltersVisibility",
                                  "statusMenu",
                                  event,
                                )
                              }
                            >
                              <Button
                                className={`${
                                  secondarySelectedFilters.filterStatus.length >
                                  0
                                    ? "Search-Filter-Selected"
                                    : "Search-Filter-Empty"
                                }`}
                              >
                                Status
                                <DownOutlined />
                              </Button>
                            </Dropdown>
                            <Dropdown
                              overlay={proprietorMenu}
                              overlayClassName="Filters-Menu"
                              open={secondaryFiltersVisibility.proprietorMenu}
                              onVisibleChange={(event) =>
                                this.changeStateValue_L1(
                                  "secondaryFiltersVisibility",
                                  "proprietorMenu",
                                  event,
                                )
                              }
                            >
                              <Button
                                className={`${
                                  secondarySelectedFilters.filterProprietor
                                    .length > 0
                                    ? "Search-Filter-Selected"
                                    : "Search-Filter-Empty"
                                }`}
                              >
                                Proprietor
                                <DownOutlined />
                              </Button>
                            </Dropdown>
                            <Dropdown
                              overlay={
                                <DateRangePicker
                                  type={"Date of Application"}
                                  startValue={
                                    secondarySelectedFilters.date_of_application_startValue
                                  }
                                  endValue={
                                    secondarySelectedFilters.date_of_application_endValue
                                  }
                                  setDate={(val) => this.setDate1(val)}
                                  range={this.state.range1}
                                  handleDateString={(
                                    startValue,
                                    endValue,
                                    dateString,
                                  ) =>
                                    this.setState({
                                      secondarySelectedFilters: {
                                        ...this.state.secondarySelectedFilters,
                                        date_of_application_startValue:
                                          startValue,
                                        date_of_application_endValue: endValue,
                                        date_of_application:
                                          startValue !== null ||
                                          endValue !== null
                                            ? dateString
                                            : null,
                                      },
                                    })
                                  }
                                />
                              }
                              overlayClassName="filter-menu"
                            >
                              <Button
                                className={`${
                                  secondarySelectedFilters.date_of_application
                                    .length > 0
                                    ? "Search-Filter-Selected"
                                    : "Search-Filter-Empty"
                                }`}
                              >
                                DoA <DownOutlined />
                              </Button>
                            </Dropdown>
                            <Dropdown
                              overlay={
                                <>
                                  <DateRangePicker
                                    type={"Date of Usage"}
                                    startValue={
                                      secondarySelectedFilters.date_of_usage_startValue
                                    }
                                    endValue={
                                      secondarySelectedFilters.date_of_usage_endValue
                                    }
                                    setDate={(val) => this.setDate2(val)}
                                    range={this.state.range2}
                                    handleDateString={(
                                      startValue,
                                      endValue,
                                      dateString,
                                    ) =>
                                      this.setState({
                                        secondarySelectedFilters: {
                                          ...this.state
                                            .secondarySelectedFilters,
                                          date_of_usage_startValue: startValue,
                                          date_of_usage_endValue: endValue,
                                          date_of_usage:
                                            startValue !== null ||
                                            endValue !== null
                                              ? dateString
                                              : null,
                                        },
                                      })
                                    }
                                  />
                                  <div
                                    style={{
                                      textAlign: "center",
                                      lineHeight: "32px",
                                    }}
                                  >
                                    <Checkbox
                                      checked={
                                        this.state.secondarySelectedFilters
                                          .date_of_usage_proposed
                                      }
                                      onChange={(event) =>
                                        this.changeStateValue_L1(
                                          "secondarySelectedFilters",
                                          "date_of_usage_proposed",
                                          event.target.checked,
                                        )
                                      }
                                    >
                                      Proposed
                                    </Checkbox>
                                  </div>
                                </>
                              }
                              overlayClassName="filter-menu"
                            >
                              <Button
                                className={`${
                                  secondarySelectedFilters.date_of_usage
                                    .length > 0 ||
                                  secondarySelectedFilters.date_of_usage_proposed
                                    ? "Search-Filter-Selected"
                                    : "Search-Filter-Empty"
                                }`}
                              >
                                DoU <DownOutlined />
                              </Button>
                            </Dropdown>
                            <Tooltip title="Search application name or number">
                              <Search
                                value={secondarySelectedFilters.containsTerm}
                                onChange={(event) =>
                                  this.changeStateValue_L1(
                                    "secondarySelectedFilters",
                                    "containsTerm",
                                    event.target.value,
                                  )
                                }
                                style={{
                                  width: "170px",
                                  margin: "0px 5px 0px 2px",
                                }}
                                placeholder="Search application name or number"
                                enterButton={
                                  <Button
                                    style={{
                                      backgroundColor: "#4285f4",
                                      color: "#ffffff",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Apply
                                  </Button>
                                }
                                onSearch={() =>
                                  this.handleApplySecondaryFilters()
                                }
                              />
                            </Tooltip>
                            <Button
                              type="link"
                              style={{ padding: "0px 5px" }}
                              onClick={() => this.handleResetSecondaryFilters()}
                            >
                              Reset
                            </Button>
                            <Cascader
                              style={{
                                width: "10vw",
                                minWidth: "100px",
                                margin: "0px 5px",
                              }}
                              options={[
                                {
                                  value: "ASC",
                                  label: "Ascending",
                                  children: [
                                    {
                                      value: "CLASS",
                                      label: "Class",
                                    },
                                    {
                                      value: "APPLICATION NUMBER",
                                      label: "Application Number",
                                    },
                                    {
                                      value: "APPLIED FOR",
                                      label: "Applied For",
                                    },
                                    {
                                      value: "STATUS",
                                      label: "Status",
                                    },
                                    {
                                      value: "DATE OF APPLICATION",
                                      label: "Date of Application",
                                    },
                                    {
                                      value: "DATE OF USAGE",
                                      label: "Date of Usage",
                                    },
                                  ],
                                },
                                {
                                  value: "DESC",
                                  label: "Descending",
                                  children: [
                                    {
                                      value: "CLASS",
                                      label: "Class",
                                    },
                                    {
                                      value: "APPLICATION NUMBER",
                                      label: "Application Number",
                                    },
                                    {
                                      value: "APPLIED FOR",
                                      label: "Applied For",
                                    },
                                    {
                                      value: "STATUS",
                                      label: "Status",
                                    },
                                    {
                                      value: "DATE OF APPLICATION",
                                      label: "Date of Application",
                                    },
                                    {
                                      value: "DATE OF USAGE",
                                      label: "Date of Usage",
                                    },
                                  ],
                                },
                                {
                                  value: "CLEAR",
                                  label: "Clear All",
                                  children: [],
                                },
                              ]}
                              allowClear={false}
                              placeholder="Sorting"
                              expandTrigger="hover"
                              value={secondarySelectedSorting.sortValue}
                              onChange={(value) =>
                                this.handleApplySecondarySorting(value)
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                    </header>
                    <div
                      className="General-Content Flex-Content vertical_scroll"
                      style={{
                        height: "calc(100vh - 350px)",
                        overflowX: "hidden",
                        overflowY: "auto",
                      }}
                    >
                      {proprietorMarkStatus === LOADING && (
                        <div
                          className="spin-container"
                          style={{ width: "100%" }}
                        >
                          <Spin size="large" />
                        </div>
                      )}
                      {proprietorMarkStatus === SUCCESS && (
                        <>
                          {selectedView === "cards" &&
                            proprietorMark.marks.length > 0 &&
                            proprietorMark.marks.map((eachMark) => (
                              <GeneralMarkCard
                                key={eachMark.id}
                                eachMark={eachMark}
                                onSelectMark={(eachMark, checked) =>
                                  this.handleSecondarySelectMark(
                                    eachMark.id,
                                    eachMark.application_number,
                                    checked,
                                  )
                                }
                                checked={
                                  customReportData.data[this.state.expandRecord]
                                    ? customReportData.data[
                                        this.state.expandRecord
                                      ].selected_mark_id_list.includes(
                                        eachMark.id,
                                      ) ||
                                      customReportData.data[
                                        this.state.expandRecord
                                      ].select_all ||
                                      this.state.customReportData.select_all
                                    : false
                                }
                                selectAllCards={
                                  (customReportData.data[
                                    this.state.expandRecord
                                  ] &&
                                    customReportData.data[
                                      this.state.expandRecord
                                    ].select_all) ||
                                  this.state.customReportData.select_all
                                }
                                changeStateValue_L1={(x, y, application) => {
                                  sessionStorage.setItem(
                                    "application",
                                    application,
                                  );
                                  window.open(`/search/trademark-profile`);
                                }}
                                styleCard={{ textAlign: "left" }}
                                similarity={null}
                                status={checkNA(eachMark.status)}
                                proprietorClickable={true}
                                onProprietorClick={(proprietor) => {
                                  sessionStorage.setItem(
                                    "proprietor",
                                    proprietor,
                                  );
                                  window.open(
                                    `/search/proprietor-profile/${proprietor}`,
                                  );
                                }}
                              />
                            ))}
                          {selectedView === "table" &&
                            proprietorMark.marks.length > 0 && (
                              <table className="Table">
                                <thead className="Table-Head">
                                  <tr className="Table-Head-Row">
                                    <th
                                      className="Table-Head-Cell Black-Shadow"
                                      style={{ width: "4%", minWidth: "50px" }}
                                    ></th>
                                    <th className="Table-Head-Cell Black-Shadow">
                                      TRADEMARK
                                    </th>
                                    <th className="Table-Head-Cell Black-Shadow">
                                      STATUS
                                    </th>
                                    <th className="Table-Head-Cell Black-Shadow">
                                      TYPE
                                    </th>
                                    <th className="Table-Head-Cell Black-Shadow">
                                      PROPRIETORS
                                    </th>
                                    <th className="Table-Head-Cell Black-Shadow">
                                      CLASSES
                                    </th>
                                    <th className="Table-Head-Cell Black-Shadow">
                                      DoA | DoU
                                    </th>
                                    <th
                                      className="Table-Head-Cell Black-Shadow"
                                      style={{ width: "4%", minWidth: "50px" }}
                                    >
                                      {this.state
                                        .tableDescriptionRowsVisibility ? (
                                        <Tooltip
                                          placement="left"
                                          title="Hide Descriptions"
                                        >
                                          <MinusSquareFilled
                                            style={{
                                              fontSize: "20px",
                                              verticalAlign: "baseline",
                                            }}
                                            onClick={() =>
                                              this.setState({
                                                tableDescriptionRowsVisibility: false,
                                              })
                                            }
                                          />
                                        </Tooltip>
                                      ) : (
                                        <Tooltip
                                          placement="left"
                                          title="Show Descriptions"
                                        >
                                          <PlusSquareFilled
                                            style={{
                                              fontSize: "20px",
                                              verticalAlign: "baseline",
                                            }}
                                            onClick={() =>
                                              this.setState({
                                                tableDescriptionRowsVisibility: true,
                                              })
                                            }
                                          />
                                        </Tooltip>
                                      )}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="Table-Body">
                                  {proprietorMark.count > 0 &&
                                    proprietorMark.marks.map(
                                      (eachRow, rowIndex) => {
                                        return (
                                          <React.Fragment
                                            key={eachRow.application_number}
                                          >
                                            <tr
                                              className={`Table-Body-Row ${
                                                rowIndex % 2 === 0
                                                  ? "Row-Even"
                                                  : "Row-Odd"
                                              }`}
                                            >
                                              <td>
                                                <Checkbox
                                                  onClick={(event) =>
                                                    this.handleSecondarySelectMark(
                                                      eachRow.id,
                                                      eachRow.application_number,
                                                      event.target.checked,
                                                    )
                                                  }
                                                  checked={
                                                    customReportData.data[
                                                      this.state.expandRecord
                                                    ]
                                                      ? customReportData.data[
                                                          this.state
                                                            .expandRecord
                                                        ].selected_mark_id_list.includes(
                                                          eachRow.id,
                                                        ) ||
                                                        customReportData.data[
                                                          this.state
                                                            .expandRecord
                                                        ].select_all
                                                      : false
                                                  }
                                                  disabled={
                                                    customReportData.data[
                                                      this.state.expandRecord
                                                    ] &&
                                                    customReportData.data[
                                                      this.state.expandRecord
                                                    ].select_all
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <span>
                                                  {eachRow.applied_for}
                                                </span>
                                                <br />
                                                {eachRow.associated_image && (
                                                  <>
                                                    <span>
                                                      <img
                                                        src={
                                                          eachRow.associated_image
                                                        }
                                                        height="70px"
                                                        width="70px"
                                                        alt=""
                                                      />
                                                    </span>
                                                    <br />
                                                  </>
                                                )}
                                                <span
                                                  onClick={() => {
                                                    sessionStorage.setItem(
                                                      "application",
                                                      eachRow.application_number,
                                                    );
                                                    window.open(
                                                      `/search/trademark-profile`,
                                                    );
                                                  }}
                                                  className="Application-Number"
                                                >
                                                  {eachRow?.application_number}
                                                </span>
                                              </td>
                                              <td>{eachRow?.status}</td>
                                              <td>{eachRow?.type?.name}</td>
                                              <td>
                                                {eachRow.proprietor.map(
                                                  (
                                                    eachProprietor,
                                                    index,
                                                    allProprietors,
                                                  ) => (
                                                    <React.Fragment
                                                      key={`${eachRow.id}-${eachProprietor.id}`}
                                                    >
                                                      <span>
                                                        <span>
                                                          <strong>
                                                            Proprietor:{" "}
                                                          </strong>
                                                          <span
                                                            style={{
                                                              padding:
                                                                "0px 5px",
                                                            }}
                                                            className="Application-Number"
                                                            onClick={() => {
                                                              sessionStorage.setItem(
                                                                "proprietor",
                                                                eachProprietor.id,
                                                              );
                                                              window.open(
                                                                `/search/proprietor-profile/${eachProprietor.id}`,
                                                              );
                                                            }}
                                                          >
                                                            {
                                                              eachProprietor.name
                                                            }
                                                          </span>
                                                        </span>
                                                        <br />
                                                        <span>
                                                          <strong>
                                                            Address:{" "}
                                                          </strong>
                                                          <span
                                                            style={{
                                                              padding:
                                                                "0px 5px",
                                                            }}
                                                          >
                                                            {
                                                              eachProprietor.address
                                                            }
                                                          </span>
                                                        </span>
                                                      </span>
                                                      {allProprietors.length ===
                                                      index + 1 ? (
                                                        ""
                                                      ) : (
                                                        <hr />
                                                      )}
                                                    </React.Fragment>
                                                  ),
                                                )}
                                              </td>
                                              <td>
                                                {eachRow.associated_class.map(
                                                  (
                                                    eachClass,
                                                    index,
                                                    allClasses,
                                                  ) =>
                                                    allClasses.length ===
                                                    index + 1
                                                      ? `${eachClass}`
                                                      : `${eachClass}, `,
                                                )}
                                              </td>
                                              <td>
                                                <span>
                                                  DoA:{" "}
                                                  {eachRow.application_date
                                                    ? eachRow.application_date
                                                    : "N/A"}
                                                </span>
                                                <br />
                                                <span>
                                                  DoU:{" "}
                                                  {eachRow.date_of_usage
                                                    ? eachRow.date_of_usage
                                                    : "Proposed"}
                                                </span>
                                              </td>
                                              <td></td>
                                            </tr>
                                            {this.state
                                              .tableDescriptionRowsVisibility && (
                                              <tr
                                                className={`Table-Body-Row ${
                                                  rowIndex % 2 === 0
                                                    ? "Row-Even"
                                                    : "Row-Odd"
                                                }`}
                                              >
                                                <td
                                                  colSpan="8"
                                                  style={{
                                                    verticalAlign: "top",
                                                    padding: 10,
                                                  }}
                                                >
                                                  {eachRow.description
                                                    ? eachRow.description
                                                    : "Description"}
                                                </td>
                                              </tr>
                                            )}
                                          </React.Fragment>
                                        );
                                      },
                                    )}
                                </tbody>
                              </table>
                            )}
                        </>
                      )}

                      {proprietorMarkStatus === SUCCESS &&
                        proprietorMark.marks.length === 0 && (
                          <Empty style={{ width: "100%" }} />
                        )}

                      {proprietorMarkStatus === ERROR && (
                        <div
                          className="error-container"
                          style={{ width: "100%" }}
                        >
                          <Title level={4}>
                            unfortunately an error has occurred please try again
                            later. will connect you shortly.
                          </Title>
                        </div>
                      )}
                    </div>
                    <footer className="General-Footer Box-Shadow">
                      <Radio.Group
                        onChange={(event) =>
                          this.props.TPAction(
                            PS_SELECT_VIEW,
                            event.target.value,
                          )
                        }
                        value={selectedView}
                        style={{ float: "left" }}
                      >
                        <Radio.Button value="cards">Cards</Radio.Button>
                        <Radio.Button value="table">Table</Radio.Button>
                      </Radio.Group>
                      <Button
                        style={{ float: "left", margin: "0px 5px" }}
                        onClick={() =>
                          this.changeStateValue_L1(
                            "drawersVisibility",
                            "resultsAnalysis",
                            true,
                          )
                        }
                      >
                        <PieChartOutlined style={{ fontSize: "14px" }} />
                      </Button>
                      <Pagination
                        showQuickJumper
                        defaultCurrent={1}
                        current={
                          proprietorMarkPageNumber
                            ? proprietorMarkPageNumber
                            : 1
                        }
                        total={proprietorMark && proprietorMark.count}
                        showTotal={(total, range) =>
                          `${range[0]}-${range[1]} of ${total} `
                        }
                        pageSize={100}
                        showSizeChanger={false}
                        onChange={(pageNumber) =>
                          this.handleSecondaryPageChange(pageNumber)
                        }
                      />
                    </footer>
                  </div>
                )}
              </React.Fragment>
            ))}

          {this.props.proprietorsSearchStatus === SUCCESS &&
            this.props.count === 0 && (
              <div style={{ display: "table", height: "50vh", width: "100%" }}>
                <Empty
                  style={{ display: "table-cell", verticalAlign: "middle" }}
                />
              </div>
            )}

          {this.props.proprietorsSearchStatus === LOADING && (
            <div
              style={{
                display: "table",
                height: "50vh",
                width: "100%",
                verticalAlign: "middle",
              }}
            >
              <Spin size="large" style={{ display: "table-cell" }} />
            </div>
          )}

          {this.props.proprietorsSearchStatus === ERROR && (
            <div style={{ display: "table", height: "50vh", width: "100%" }}>
              <div
                style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                  textAlign: "center",
                }}
              >
                <h1>
                  Unfortunately, due to the occurrence of an error, we were
                  unable to fetch the data from the server.
                </h1>
                <h1>
                  We apologize for the inconvenience. Please, try again after
                  some time.
                </h1>
              </div>
            </div>
          )}
        </div>
        <footer className="General-Footer">
          <Pagination
            showQuickJumper
            defaultCurrent={1}
            current={
              this.props.searchParams.page ? this.props.searchParams.page : 1
            }
            total={this.props.count}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} `}
            pageSize={100}
            showSizeChanger={false}
            onChange={(pageNumber) => this.handlePageChange(pageNumber)}
          />
        </footer>
        {/* Custom Report Details */}
        <Drawer
          title={<h3>Custom Report Details</h3>}
          placement="right"
          width={400}
          closable={false}
          open={this.state.drawersVisibility.customReport}
          onClose={() =>
            this.changeStateValue_L1("drawersVisibility", "customReport", false)
          }
        >
          <form>
            Enter the name of your report:
            <br />
            <Input
              onChange={(event) => {
                if (/^[ \w!?:_\-()@#,;<>[\]{}|]*$/g.test(event.target.value)) {
                  this.changeStateValue_L1(
                    "customReportData",
                    "reportName",
                    event.target.value,
                  );
                }
              }}
              value={this.state.customReportData.reportName}
              placeholder="Report's name (Optional)"
            />
            <br />
            <br />
            Choose your preferred report type:
            <br />
            <Radio.Group
              value={this.state.customReportData.reportType}
              onChange={(event) =>
                this.changeStateValue_L1(
                  "customReportData",
                  "reportType",
                  event.target.value,
                )
              }
            >
              <Radio value="URGENT">Urgent Report</Radio>
              <br />
              <Radio value="REALTIME">Realtime report</Radio>
            </Radio.Group>
            <br />
            <br />
            Choose your preferred report format:
            <br />
            <Checkbox
              checked={this.state.customReportData.reportFormat.includes(PDF)}
              onChange={(event) => {
                if (event.target.checked) {
                  this.changeStateValue_L1("customReportData", "reportFormat", [
                    ...this.state.customReportData.reportFormat,
                    PDF,
                  ]);
                } else {
                  this.changeStateValue_L1(
                    "customReportData",
                    "reportFormat",
                    this.state.customReportData.reportFormat.filter(
                      (e) => e !== PDF,
                    ),
                  );
                }
              }}
            >
              PDF
            </Checkbox>
            <br />
            <Checkbox
              checked={this.state.customReportData.reportFormat.includes(EXCEL)}
              onChange={(event) => {
                if (event.target.checked) {
                  this.changeStateValue_L1("customReportData", "reportFormat", [
                    ...this.state.customReportData.reportFormat,
                    EXCEL,
                  ]);
                } else {
                  this.changeStateValue_L1(
                    "customReportData",
                    "reportFormat",
                    this.state.customReportData.reportFormat.filter(
                      (e) => e !== EXCEL,
                    ),
                  );
                }
              }}
            >
              EXCEL
            </Checkbox>
            <br />
            <br />
            <Alert
              message="Note: If no format is selected, the report won't be sent to your e-mail but the changes will get reflected in the reports section."
              type="warning"
            />
            <br />
            <br />
            <Button
              type="primary"
              ghost
              style={{ marginRight: "10px" }}
              loading={this.props.searchReportStatus === LOADING}
              onClick={() => this.handleConfirmReport()}
            >
              Confirm
            </Button>
            <Button
              danger
              ghost
              onClick={() =>
                this.changeStateValue_L1(
                  "drawersVisibility",
                  "customReport",
                  false,
                )
              }
            >
              Cancel
            </Button>
          </form>
        </Drawer>
        {/* Custom Report Details */}
        {/* Results Analysis */}
        <Drawer
          title={<h2>Results Analysis(Proprietor-Profile)</h2>}
          placement="right"
          width={800}
          closable={true}
          open={this.state.drawersVisibility.resultsAnalysis}
          onClose={() =>
            this.changeStateValue_L1(
              "drawersVisibility",
              "resultsAnalysis",
              false,
            )
          }
          className="analysis-body"
          bodyStyle={{
            padding: 0,
          }}
        >
          <div className="analysis-body-container vertical_scroll">
            {this.state.drawersVisibility.resultsAnalysis && (
              <ResultsAnalysis
                analysisStatus={proprietorAnalysisStatus}
                statusesDistribution={
                  proprietorAnalysis
                    ? proprietorAnalysis.status_distribution
                    : []
                }
                classesDistribution={
                  proprietorAnalysis
                    ? proprietorAnalysis.class_distribution
                    : []
                }
                yearsDistribution={
                  proprietorAnalysis ? proprietorAnalysis.year_distribution : []
                }
              />
            )}
          </div>
        </Drawer>
        {/* Results Analysis */}
      </div>
    );
  }
}

const mapDispatchToProps = {
  getProprietorsSearch,
  getProprietorProfileDetail,
  postReport,
  TPAction,
  logOut,
};

function mapStateToProps(state) {
  const {
    proprietorsSearchStatus,
    proprietors,
    count,
    filters,
    searchParams,

    proprietorMark,
    proprietorMarkStatus,
    proprietorMarkFilters,
    proprietorMarkSorting,
    proprietorMarkPageNumber,

    proprietorAnalysis,
    proprietorAnalysisStatus,

    proprietorProfile,
    proprietorProfileStatus,

    similarProprietor,
    similarProprietorStatus,

    selectedView,
  } = state.searchState.proprietorsSearch;
  const { searchReport, searchReportStatus } = state.searchState.searchBar;

  return deepFreeze({
    proprietorsSearchStatus,
    proprietors,
    count,
    filters,
    searchParams,

    proprietorMark,
    proprietorMarkStatus,
    proprietorMarkFilters,
    proprietorMarkSorting,
    proprietorMarkPageNumber,

    proprietorAnalysis,
    proprietorAnalysisStatus,

    proprietorProfile,
    proprietorProfileStatus,

    similarProprietor,
    similarProprietorStatus,

    selectedView,

    searchReport,
    searchReportStatus,

    searchPath: state.searchState.searchBar.searchPath,
    searchAlert: state.alertMessages.search,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(ProprietorsSearch);
